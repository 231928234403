export default {
  form: {
    title: "Connection à Xplora",
    username: "Numéro de téléphone",
    password: "Veuillez saisir le code reçu par SMS",
    language: "Langue",
    submit: "Se connecter",
    continue: "Suivant",
    usernameValidationMessage: "Entrer le numéro de téléphone des parents.",
    otpHint: "",
  },
  logoutDialog: {
    title: "Utilisateur connecté ",
  },
  loginInfo: {
    title: "Ètes-vous abonné à Xplora?",
    content: `NOTE: Si l'abonnement est pour une montre Xplora, entrer le numéro de téléphone des parents, pas le numéro de la montre. \n \n Veuillez noter qu'il peut s'écouler 30 minutes entre la commande et le moment ou la connection à votre compte utilisateur est possible.`,
  },
} as const;
