import { lazy, ReactElement, Suspense } from "react";

interface FooterProps {
  /** Name of the FRONTEND component that should be rendered  */
  uiComponent?: string;
  /** Other specific footer config */
  config?: any;
}

export function FooterLoader({
  uiComponent,
  config,
}: FooterProps): ReactElement {
  /**
   * Below line takes the uiComponent string specified in the app config and
   * And lazy loads the correct footer depending on value. When adding new
   * footers they need to be the default export of the file since import()
   * syntax requires it and cant handle named exports (See XploraFooter).
   */
  const CompanyFooter = lazy(() => import(`./footers/${uiComponent}`));

  return (
    <Suspense>
      <CompanyFooter config={config} />
    </Suspense>
  );
}
