import { Card, CardContent, Typography } from "common/components";
import { ReactElement } from "react";

interface LoginInfoBlockProps {
  title?: string;
  content: string;
}

/**
 * A basic content component for the login screen
 * @param title The title of the text content
 * @param content Text content to display
 * @returns A ReactElement
 */
export function LoginInfoBlock({
  title,
  content,
}: LoginInfoBlockProps): ReactElement {
  return (
    <Card sx={{ width: "100%", height: "100%", padding: 2 }}>
      <CardContent>
        <Typography gutterBottom variant="h4">
          {title}
        </Typography>
        <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
}
