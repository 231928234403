import action from "i18n/lang/fr/action";
import common from "i18n/lang/fr/common";
import error from "i18n/lang/fr/error";
import invoice from "i18n/lang/fr/invoice";
import languages from "i18n/lang/fr/languages";
import login from "./login";

export const fr = {
  action,
  common,
  invoice,
  languages,
  error,
  login,
} as const;
