export default {
  nextInvoice: "Facture suivante",
  latestInvoice: "Dernière facture",
  invoice: "Facture",
  invoice_other: "Factures",
  status: "Statut",
  statementType: "Pièce comptable",
  invoiceId: "N° de facture",
  creditNoteId: "N° de note de crédit",
  name: "Nom",
  amount: "Montant",
  invoiceDate: "Date de facturation",
  creditNoteDate: "Date du crédit",
  dueDate: "Date d'échéance",
  creditDate: "Date du crédit",
  creditedBy: "Créditée par",
  creditNoteType: "Note de crédit de type",
  creditedInvoiceId: "N° de la facture créditée",
  creditReason: "Motif du crédit",
  paymentMethod: "Méthode de paiement",
  referenceNumber: "N° de référence",
  netAmount: "Montant net",
  rounding: "Arrondi",
  taxAmount: "Taxes",
  totalAmount: "Montant total",
  address: "Adresse",
  totalAmountTaxIncluded: "Montant total TVA comprise",
  totalAmountTaxExcluded: "Montant total hors TVA",
  downloadSuccess: "Facture {{invoiceId}} téléchargée",
  noLatestInvoice: "Aucune facture trouvée",
  noInvoices: "Aucune facture trouvée",
  period: "Période",
  costsForUpcomingPeriod:
    "Coûts pour la période à venir, pour le numéro de téléphone {{subscription}}",
  noCostsForUpcomingPeriod: "Pas de coûts pour la période à venir",
  changeDueDate: {
    dueDate: "Nouvelle date d'échéance",
  },

  newDueDate: "Nouvelle date d'échéance",
  configureInvoiceDunning: {
    blockReminders: "Bloquer les rappels",
    unblockReminders: "Débloquer les rappels",
    blockDebtCollection: "Bloquer le recouvrement",
    unblockDebtCollection: "Débloquer le recouvrement",
  },
  remainingAmount: "Montant disponible",
  payInvoice: {
    paidAmount: "Montant payé",
    paymentDate: "Date du paiement",
    paymentReference: "Référence du paiement",
    paymentType: "Type de paiement",
  },
  creditInvoice: {
    date: "Date du crédit",
    invoice: "N° de la facture créditée",
    credit: "Créditer",
    totalInvoicedAmount: "Montant total facturé",
    creditedAmount: "Montant crédité",
    creditApprove: "Approuver directement la note de crédit",
    creditReason: "Motif du crédit",
    negativeOrZeroCreditedAmountWarning:
      "Le montant crédité ne peut pas être négatif ou nul.",
    remainingMaxAmountToCreditWarning:
      "Le montant crédité ne peut pas dépasser le Montant maximum créditable.",
    maxAmountToCredit: "Montant maximum créditable",
    remainingAmount: "Montant disponible (TVA comprise)",
    revertCredit: "Annuler le crédit",
    creditInvoiceRow: "Créditer cette position",
    remainingAmountToCredit: "Montant disponible créditable",
    amountInclTax: "Montant TVA comprise",
    amountExclTax: "Montant hors TVA",
  },
} as const;
