export default {
  urlNotFound: "Die Seite wurde nicht gefunden",
  notFoundOrNotAvailable:
    "Die Seite, auf die du zugreifen möchtest, existiert nicht oder wurde entfernt",
  yourApplications: "Deine Anwendungen",
  homePage: "Anwendungen",
  applications: "Deine Anwendungen",
  customerCare: "Kundenbetreuung",
  printerQueue: "Druckerwarteschlange",
  loading: "Lädt...",
  loadingApp: "Webanwendung lädt...",
  logout: "Ausloggen",
  switchTo: "Wechsel zu",
  yourChangesAreSaved: "Die Änderungen wurden gespeichert",
  filters: "Filter",
  configuration: "Konfiguration",
  rowsperpage: "Reihen pro Seite",
  customerGroup: "Kundengruppe",
  help: "Hilfe",
  notifications: "Meldungen",
  dashboard: "Dashboard",
  documentation: "Dokumentation",
  downloadingDocument: "Dokument herunterladen...",
  loadingDocument: "Dokument wird geladen...",
  noDocumentAvailable:
    "Der Zugriff auf das angeforderte Dokument ist entweder eingeschränkt oder vorübergehend nicht möglich",
  nextPage: "Nächste Seite",
  previousPage: "Vorige Seite",
  downloadingFile: "{{fileName}} wird heruntergeladen",
  downloadFile: "{{fileName}} herunterladen",
  fileName: "Dateiname",
  about: "Über diese Anwendung",
  version: "Version: {{version}}",
  errorNoIdentityProvider:
    "Es konnte kein Identitätsanbieter aus der Datenbank abgerufen werden.",
  name: "Name",
  customerName: "Kundenname",
  status: "Status",
  address: "Adresse",
  invoices: "Rechnungen",
  inventories: "Bestandsposten",
  type: "Typ",
  products: "Produkten",
  showHideButton: {
    show: "Komponente anzeigen",
    hide: "Komponente ausblenden",
  },
  bookmarks: {
    noFoundBookmarks: "Keine {{entityType}} lesezeichen vorhanden",
    noFoundEntityBookmarks: "Keine lesezeichen vorhanden",
    addToBookmarks: "Zu den Lesezeichen hinzufügen",
    removeFromBookmarks: "Aus den Lesezeichen entfernen",
  },
  entityTypeName: {
    account_one: "Account",
    account_other: "Accounts",
    address_one: "Adresse",
    address_other: "Adressen",
    adjustment_one: "Berichtigung",
    adjustment_other: "Berichtigungen",
    webApplication_one: "Anwendung",
    webApplication_other: "Anwendungen",
    customer_one: "Kunde",
    customer_other: "Kunden",
    document_one: "Dokument",
    document_other: "Dokumenten",
    invoice_one: "Rechnung",
    invoice_other: "Rechnungen",
    inventory_one: "Bestandspost",
    inventory_other: "Bestandsposten",
    message_one: "Nachricht",
    message_other: "Nachrichten",
    note_one: "Notiz",
    note_other: "Notizen",
    npcase_one: "Portierung Antrag",
    npcase_other: "Portierung Antragen",
    payment_one: "Zahlung",
    payment_other: "Zahlungen",
    product_one: "Produkt",
    product_other: "Produkten",
    productOffering_one: "Produktangebot",
    productOffering_other: "Produktangebote",
    subscription_one: "Abonnement",
    subscription_other: "Abonnemente",
    task_one: "Aufgabe",
    task_other: "Aufgaben",
    tasklist_one: "Aufgabenliste",
    tasklist_other: "Aufgabenlisten",
    user_one: "Nutzer",
    user_other: "Nutzer",
    nrdbMessage_one: "Mitteilung",
    nrdbMessage_other: "Mitteilungen",
    nrdbNumber_one: "Telefonnummer",
    nrdbNumber_other: "Telefonnummern",
    nrdbProxy_one: "Proxy",
    nrdbProxy_other: "Proxies",
    order_one: "Auftrag",
    order_other: "Aufträge",
  },
  dateGroupTitle: {
    today: "Heute",
    last_week: "Während der letzten Woche",
    last_month: "Während des letzten Monats",
    earlier: "Früher",
  },
  sideBar: {
    show: "Anzeigen",
    hide: "Ausblenden",
    showTooltip: "Die Navigationsleiste anzeigen",
    hideTooltip: "Die Navigationsleiste ausblenden",
  },
  loggedOut: {
    message: "Du wurdest abgemeldet",
    gotoLogin: "Gehe zur Loginseite",
  },
  properties: "Eigenschaften",
  noAvailableData: "Keine Daten verfügbar",
  noValue: "<keinen Wert>",
  noValueSelected: "<Keins>",
  deleteSelected: "Ausgewählte Elemente löschen?",
  copyFrom: "Kopieren von",
  buttons: {
    accept: "Annehmen",
    cancel: "Abbrechen",
    continue: "Fortsetzen",
    clear: "Löschen",
    close: "Schliessen",
    copy: "Kopieren",
    copyFrom: "Kopieren von der {{addressType}}adresse",
    create: "Erstellen",
    decline: "Ablehnen",
    delete: "Löschen",
    dismiss: "Abweisen",
    download: "Herunterladen",
    ok: "Ok",
    print: "Drucken",
    refreshPageData: "Seitendaten aktualisieren",
    reset: "Zurücksetzen",
    save: "Speichern",
    update: "Aktualisieren",
    search: "Suchen",
    undo: "Rückgängig machen",
    moreDetails: "Weitere Details",
    showAll: "Alle anzeigen",
    showAllHiddenAfter: "Ergebnisse für {{search}} in {{entityType}}",
    showCountMore: "Zeige {{count}} weitere",
    showPDF: "PDF-Datei anzeigen",
    preview: "Vorschau",
    previewHiddenAfter: "next invoice",
    next: "Weiter",
    back: "Zurück",
    finish: "Fertigstellen",
    generateGUID: "GUID erzeugen",
    clearGUID: "GUID löschen",
    generatePINCode: "PIN erzeugen",
    clearPINCode: "PIN Code löschen",
    toggleVisibility: "Sichtbarkeit des Passworts umschalten",
    currentUserAccount: "Konto des aktuellen Nutzers",
    accountMenu: "Konto-Menü",
    applicationSwitcher: "Anwendungswechsler",
  },
  forms: {
    fieldIsMandatory: "Dieses Feld ist ein Pflichtfeld",
    mandatory: "{{label}} (Pflichtfeld)",
    minLength: "Dieses Feld benötigt mindestens {{count}} Zeichen",
    maxLength: "Dieses Feld erlaubt maximal {{count}} Zeichen",
    lengthBetween:
      "Dieses Feld benötigt zwischen {{minLength}} und {{maxLength}} Zeichen",
    lengthExactly: "Dieses Feld benötigt genau {{count}} Zeichen",
    pattern: "Der Feldwert muss dem Muster {{pattern}} entsprechen",
    formatShortSocialSecNo: "XX DDMMYY A YY Z",
    formatLongSocialSecNo: "XX DDMMYY A YY Z",
    date: {
      dateTimeError:
        "Das gewählte Datum oder die Uhrzeit ist ungültig (Hinweis: {{hint}})",
      dateError: "Das gewählte Datum ist ungültig  (Hinweis: {{hint}})",
      timeError: "Die gewählte Uhrzeit ist ungültig  (Hinweis: {{hint}})",
    },
    tableSelectionIsMandatory: "Es muss eine Reihe ausgewählt werden",
  },
  table: {
    rowActionMenu: {
      tooltip: "Aktionen für die gewählte Reihe",
      ariaLabel: "Aktionsmenü",
    },
    multiRowActionMenu: {
      tooltip: "Aktionen für die gewählten Reihen",
      ariaLabel: "Aktionsmenü",
    },
    expandRow: "Reihe uasklappen",
    defaultError: "Beim Laden der Tabelle ist ein Fehler aufgetreten",
  },
  cookieText:
    "Billiant verlangt, dass die unbedingt notwendigen Cookies vom Browser akzeptiert werden, um die Verwaltung der Anmeldung und der Benutzereinstellungen zu ermöglichen. Die Webanwendung funktioniert nicht ohne die unbedingt erforderlichen Cookies.",
  snackbarMessageOccurredMultipleTimes: "({{times}} Mal aufgetreten)",
  fetchingData: "Daten werden abgerufen",
  keyboardShortcuts: "Tastenkürzel",
  settings: "Einstellungen",
  settingsMenu: {
    settingsMenu: "Einstellungen Menü",
    allowDnD: "Ziehen und ablegen erlauben",
  },
  navigation: "Navigation",
  filter: {
    hideFilter: "Filter ausblenden",
    showFilter: "Filter anzeigen",
  },
  searchFilter: {
    currency: "Währung",
    selectCurrency: "Währung wählen",
    textFrom: "{{text}} (von)",
    textTo: "{{text}} (bis)",
    fromValue: "Von {{value}}",
    toValue: "Bis {{value}}",
    searchText: "Suchbegriff",
    today: "Heute",
  },
  ofTotal: "von {{count}}",
  zoomIn: "Vergrößern",
  zoomOut: "Verkleinern",
  approveOrders: "Auftrag genehmigen",
  adjustmentDate: "Berechtigungsdatum",
  chargeType: "Gebührenart",
  generalLedgerInfo: "Informationen zum Hauptbuch",
  taxBundleInfo: "Informationen zur Steuerregelung",
  adjustmentDescription: "Beschreibung der Berichtigung",
  adjustmentDescriptionHelper:
    "Wähle oder gib eine Beschreibung ein, die auf der Rechnung erscheinen soll.",
  decision: "Beschluss",
  earliestInvoiceDate: "Frühestes Rechungsdatum",
  enableImmediateInvoicing: "Sofortige Rechnungsstellung ermöglichen",
  inputAmountInclVat: "Betrag inkl. {{vatRate}} MwSt. eingeben",
  amountExclVat: "Betrag (ohne {{vatRate}} MwSt.)",
  amountVat: "Mehrwertsteuer (MwSt.)",
  amountInclVat: "Betrag (inkl. {{vatRate}} MwSt.)",
  amountsInclTax: "Anzeige/Eingabe von Beträgen inklusive Steuer",
  effectiveDate: "Wirksamkeitsdatum",
  overrideBinds: "Vertragliche Bindungen/Kündigungsfrist aufheben",
  reason: "Grund",
  supressFees: "Vertragsverletzungsgebühren abschaffen",
  categoryWidget: {
    noCategoriesFound: "Keine Kategorien gefunden",
    noCategoriesFoundHelper: "Dieser Kunde hat noch keine Kategorien",
    missingCustomerScreenEntity:
      "Aktueller Bildschirm vermisst eine CUSTOMER-Entität",
    missingCustomerScreenEntityHelper:
      "Das Widget konnte keine CUSTOMER-Entität finden, die mit dem aktuellen Bildschirm verbunden ist",
  },
  historyWidget: {
    message: "Nachricht",
    oldValue: "Alte Wert",
    newValue: "Neue Wert",
    orderId: "Auftragsnummer",
    dateLastPost: "Datum der letzten Änderung: {{date}}",
  },
  dayOfMonth: "Tag im Monat",
  days: "Tagen",
  amount: "Betrag",
  amountWithCurrency: "Betrag ({{currencyCode}})",
  current: "Aktueller",
  totalAmount: "Gesamtbetrag {{amount}}",
  createdBy: "Erstellt von: {{createdBy}}",
  scoreWidget: {
    score: "Bewertung {{score}}",
    noScore: "Keine Bewertung",
  },
  reminderXSent: "{{number}}. Mahnung verschickt",
  titleIsMissing: "Titel fehlt",
  noChildWidget:
    "Keine passende Komponente gefunden. Entitätstyp: {{EntityType}}, entityTypeID: {{number}}",
  descriptionIsMissing: "Die Beschreibung fehlt",
  noResultsFound: "Keine Ergebnisse vorhanden",
  alerts_one: "{{count}} Warnung",
  alerts_other: "{{count}} Warnungen",
  unknownEntity: "Unbekannte Entität",
  breadcrumb: "Brotkrümel",
  selectAll: "Alle auswählen",
  items_one: "{{count}} Element",
  items_other: "{{count}} Elemente",
  appNameLogo: "{{appName}} Logo",
  footer:
    "Xplora wird verkauft und vertrieben von: XPLORA Technologies Deutschland GmbH, Isestraße 20, DE-20144 Hamburg\nDie Gesellschaft ist beim Handelsregister des Amtsgerichts Hamburg registriert, Register-Nummer HRB 148539",
  yes: "Ja",
  no: "Nein",
  choose: "Wählen",
  identifier: "Bezeichner",
  score: "Bewertung",
  check: "Prüfen",
  creditScore: "Kreditwürdigkeit",
  creditStatus: "Kreditwürdigkeit-Status",
  creditRatingStatus: {
    notChecked: "Nicht geprüft",
    creditOk: "Gut",
    creditWarning: "Grenzwertig",
    creditNotOk: "Schlecht",
  },
  productOfferingOptions: {
    availableRenewalOptions:
      "Es sind {{optionsLength}} Erneuerungsoptionen verfügbar.",
    noRenewalOptions:
      "Es gibt keine Erneuerungsoptionen. Das Produkt ist nicht aktiv oder hat Vertragsbindungen.",
    payWithCurrent: "Mit der aktuellen Zahlungsweise bezahlen: {{current}}",
    payWithMethod: "Zahlen mit {{method}}",
    readAndApprove: "Ich habe die Allgemeinen Geschäftsbedingungen",
    termsAndConditions: "gelesen und stimme ihnen zu.",
    payAndRenew: "Erneuern und {{amountToPay}} zahlen",
  },
  description: "Beschreibung",
  applicablePrices: "Geltende Preise",
} as const;
