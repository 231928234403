import { indexOf } from "common/utils/ListUtil";
import { isEqual } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";

export type TableSelectionType =
  | "current"
  | "next"
  | "previous"
  | "multi"
  | "single";

export function useSelectState<T extends Record<string | number, any>>(
  tableItems: T[],
  selectionCallback?: (selectedItems: T[]) => void,
  _selectedItems?: T[],
  uniqueKey?: string | number,
  isLoading = false
): {
  selectedItems: T[];
  setSelectedItems(value: React.SetStateAction<T[]>): void;
  handleSelect(item: T): void;
  handleSelectAllClick(checked: boolean): void;
  isSelected(item: T): boolean;
} {
  const [innerSelectedItems, setSelectedItems] = useState<T[]>([]);
  const selectionHistory = useRef<T[]>([]);

  const selectedItems = _selectedItems ?? innerSelectedItems;

  const handleSelect = useCallback(
    (item: T, type: TableSelectionType = "current") => {
      setSelectedItems((items) => {
        window.getSelection()?.removeAllRanges();
        const _items = _selectedItems ?? items;

        let newSelected: T[] =
          type !== "single"
            ? [..._items]
            : _items.filter((_item) => isEqual(_item, item));

        const toggleSelected = (
          targetItem: T,
          siblingResult?: "added" | "removed"
        ) => {
          const targetIndex = indexOf(targetItem, newSelected);
          if (targetIndex === -1 && siblingResult !== "removed") {
            newSelected.push(targetItem);
            selectionHistory.current.push(targetItem);
            return "added";
          }
          if (targetIndex >= 0 && siblingResult !== "added") {
            newSelected = newSelected.filter(
              (_, index) => index !== targetIndex
            );
            const selectionIndex = indexOf(
              targetItem,
              selectionHistory.current
            );
            selectionHistory.current = selectionHistory.current.filter(
              (_, index) => index !== selectionIndex
            );
            return "removed";
          }
        };

        const lastSelection =
          selectionHistory.current[selectionHistory.current.length - 1];

        if (type !== "multi" || !lastSelection) {
          const targetItem = {
            current: () => item,
            multi: () => item,
            next: () => tableItems[indexOf(item, tableItems) + 1],
            previous: () => tableItems[indexOf(item, tableItems) - 1],
            single: () => item,
          }[type]();

          const result = toggleSelected(targetItem);

          if (["next", "previous"].includes(type)) {
            toggleSelected(item, result);
          }
        } else {
          const previousIndex = indexOf(lastSelection, tableItems);
          const currentIndex = indexOf(item, tableItems);
          const start = Math.min(previousIndex, currentIndex);
          const end = Math.max(previousIndex, currentIndex) + 1;
          for (const currentItem of tableItems.slice(start, end)) {
            toggleSelected(currentItem, "added");
          }
        }
        if (!isLoading) {
          selectionCallback?.(newSelected);
        }
        return _selectedItems || isLoading ? items : newSelected;
      });
    },
    [tableItems, selectionCallback, _selectedItems, isLoading]
  );

  const handleSelectAllClick = useCallback(
    (checked: boolean) => {
      if (checked) {
        if (tableItems) {
          setSelectedItems(tableItems);
          selectionCallback?.(tableItems);
        }
        return;
      }
      selectionCallback?.([]);
      setSelectedItems([]);
    },
    [tableItems, selectionCallback]
  );

  const isSelected = useCallback(
    (item: T) => indexOf<T>(item, selectedItems) !== -1,
    [selectedItems]
  );

  useEffect(() => {
    if (!isLoading && uniqueKey) {
      setSelectedItems((items) => {
        const selections = _selectedItems ?? items;
        const newSelections = [];
        for (const selection of selections) {
          const newSelection = tableItems.find(
            (tableItem: T) => tableItem[uniqueKey] === selection[uniqueKey]
          );
          if (newSelection) {
            newSelections.push(newSelection);
          }
        }
        if (!isEqual(selections, newSelections)) {
          selectionCallback?.(newSelections);
          return newSelections;
        }
        return items;
      });
    }
  }, [tableItems, _selectedItems, selectionCallback, isLoading, uniqueKey]);

  useEffect(() => {
    if (!_selectedItems && !isLoading) {
      setSelectedItems((items) => {
        const filtered = items.filter((item) => indexOf(item, tableItems) >= 0);
        if (filtered.length < items.length) {
          selectionCallback?.(filtered);
          return filtered;
        }
        return items;
      });
    }
  }, [tableItems, selectionCallback, _selectedItems, isLoading]);

  return {
    selectedItems,
    setSelectedItems,
    handleSelect,
    handleSelectAllClick,
    isSelected,
  };
}
