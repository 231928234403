export default {
  urlNotFound: "Adresse URL invalide",
  notFoundOrNotAvailable:
    "La resource demandée n'a pas été trouvée ou n'est plus disponible",
  yourApplications: "Vos applications",
  homePage: "Applications",
  applications: "Vos applications",
  customerCare: "Gestion des clients",
  printerQueue: "Queues d'impression",
  loading: "Chargement en cours ...",
  loadingApp: "Chargement de l'application en cours ...",
  logout: "Déconnexion",
  switchTo: "Changer vers",
  yourChangesAreSaved: "Vos changements ont été sauvegardés",
  filters: "Filtres",
  configuration: "Configuration",
  rowsperpage: "Rangées par page",
  customerGroup: "Groupe de clients",
  help: "Aide",
  notifications: "Notifications",
  dashboard: "Tableau de bord",
  documentation: "Documentation",
  downloadingDocument: "Téléchargement en cours...",
  loadingDocument: "Chargement en cours...",
  noDocumentAvailable:
    "L'accès au document demandé est restreint ou impossible actuellement",
  nextPage: "Page suivante",
  previousPage: "Page précédente",
  downloadingFile: "Téléchargement de {{fileName}} en cours",
  downloadFile: "Télécharger {{fileName}}",
  fileName: "Nom du fichier",
  about: "A propos ...",
  version: "Version: {{version}}",
  errorNoIdentityProvider:
    "Aucun fournisseur d'identité n'a été trouvé dans la base de données.",
  name: "Nom",
  customerName: "Nom du client",
  status: "Statut",
  address: "Adresse",
  invoices: "Factures",
  inventories: "Inventaire",
  type: "Type",
  products: "Produits",
  showHideButton: {
    show: "Montrer le composant",
    hide: "Cacher le composant",
  },
  bookmarks: {
    noFoundBookmarks: "Aucun signet pour {{entityType}}",
    noFoundEntityBookmarks: "Aucun signet",
    addToBookmarks: "Ajouter aux signets",
    removeFromBookmarks: "Retirer des signets",
  },
  entityTypeName: {
    account_one: "Compte",
    account_other: "Comptes",
    address_one: "Adresse",
    address_other: "Adresses",
    adjustment_one: "Ajustement",
    adjustment_other: "Ajustements",
    webApplication_one: "Application",
    webApplication_other: "Applications",
    customer_one: "Client",
    customer_other: "Clients",
    document_one: "Document",
    document_other: "Documents",
    invoice_one: "Facture",
    invoice_other: "Factures",
    inventory_one: "Élément d'inventaire",
    inventory_other: "Éléments d'inventaire",
    message_one: "Message",
    message_other: "Messages",
    note_one: "Note",
    note_other: "Notes",
    npcase_one: "Cas de portage",
    npcase_other: "Cas de portage",
    payment_one: "Paiement",
    payment_other: "Paiements",
    product_one: "Produit",
    product_other: "Produits",
    productOffering_one: "Offre de produit",
    productOffering_other: "Offres de produit",
    subscription_one: "Abonnement",
    subscription_other: "Abonnements",
    task_one: "Tâche",
    task_other: "Tâches",
    tasklist_one: "Liste de tâches",
    tasklist_other: "Listes de tâches",
    user_one: "Utilisateur",
    user_other: "Utilisateurs",
    nrdbMessage_one: "Message",
    nrdbMessage_other: "Messages",
    nrdbNumber_one: "Numéro de téléphone",
    nrdbNumber_other: "Numéros de téléphone",
    nrdbProxy_one: "Proxy",
    nrdbProxy_other: "Proxies",
    order_one: "Commande",
    order_other: "Commandes",
  },
  dateGroupTitle: {
    today: "Aujourd'hui",
    last_week: "Au cours de la semaine précédente",
    last_month: "Au cours du mois précédent",
    earlier: "Plus tôt",
  },
  sideBar: {
    show: "Montrer",
    hide: "Cacher",
    showTooltip: "Afficher la barre de navigation",
    hideTooltip: "Cacher la barre de navigation",
  },
  loggedOut: {
    message: "Vous avez été déconnecté(e)",
    gotoLogin: "Reconnectez-vous",
  },
  properties: "Attributs",
  noAvailableData: "Aucune donnée disponible",
  noValue: "<pas de valeur>",
  noValueSelected: "<Aucun>",
  deleteSelected: "Supprimer l'élément sélectionné?",
  copyFrom: "Copier depuis",
  buttons: {
    accept: "Accepter",
    cancel: "Annuler",
    continue: "Continuer",
    clear: "Effacer",
    close: "Fermer",
    copy: "Copier",
    copyFrom: "Copier depuis l'adresse {{addressType}}",
    create: "Créer",
    decline: "Décliner",
    delete: "Supprimer",
    dismiss: "Supprimer",
    download: "Télécharger",
    ok: "Ok",
    print: "Imprimer",
    refreshPageData: "Rafraîchir la page",
    reset: "Réinitialiser",
    save: "Sauvegarder",
    update: "Modifier",
    search: "Rechercher",
    undo: "Défaire",
    moreDetails: "Plus de details",
    showAll: "Afficher tous les résultats",
    showAllHiddenAfter: "Résultats pour {{search}} dans {{entityType}}",
    showCountMore: "Afficher {{count}} de plus",
    showPDF: "Afficher le PDF",
    preview: "Aperçu",
    previewHiddenAfter: "prochaine facture",
    next: "Suivant",
    back: "Précédent",
    finish: "Envoyer",
    generateGUID: "Générer un GUID",
    clearGUID: "Réinitialiser le GUID",
    generatePINCode: "Générer un code PIN",
    clearPINCode: "Réinitialiser le code PIN",
    toggleVisibility: "Inverser la visibilité du mot de passe",
    currentUserAccount: "Compte de l'utilisateur connecté",
    accountMenu: "Menu 'Compte'",
    applicationSwitcher: "Échangeur d'application",
  },
  forms: {
    fieldIsMandatory: "Ce champ est obligatoire",
    mandatory: "{{label}} (obligatoire)",
    minLength: "Ce champ doit contenir au moins {{count}} caractères",
    maxLength: "Ce champ peut contenir au plus {{count}} caractères",
    lengthBetween:
      "Ce champs peut contenir entre {{minLength}} et {{maxLength}} caractères",
    lengthExactly: "Ce champ doit contenir exactement {{count}} caractères",
    pattern: "Le contenu de ce champ doit se conformer au masque {{pattern}}",
    formatShortSocialSecNo: "S-AA-MM-DP-COM-NNN-CC",
    formatLongSocialSecNo: "S-AA-MM-DP-COM-NNN-CC",
    date: {
      dateTimeError:
        "La date ou l'heure sélectionnée est invalide (indice: {{hint}})",
      dateError: "La date sélectionnée est invalide (indice: {{hint}})",
      timeError: "L'heure sélectionnée est invalide (indice: {{hint}})",
    },
    tableSelectionIsMandatory: "Une rangée doit être sélectionnée",
  },
  table: {
    rowActionMenu: {
      tooltip: "Actions pour la rangée sélectionnée",
      ariaLabel: "Menu 'Actions'",
    },
    multiRowActionMenu: {
      tooltip: "Actions pour les rangées sélectionnées",
      ariaLabel: "Menu 'Actions'",
    },
    expandRow: "Montrer les détails de la rangée",
    defaultError: "Une erreur est survenue lors du chargement de ce tableau",
  },
  cookieText:
    "Billiant exige que les cookies strictement nécessaires soient acceptés par le navigateur, pour permettre la gestion de la connexion et des préférences des utilisateurs. L'application ne fonctionne pas sans les cookies strictement nécessaires.",
  snackbarMessageOccurredMultipleTimes: "(est survenue {{times}} fois)",
  fetchingData: "Données en cours de chargement",
  keyboardShortcuts: "Raccourcis clavier",
  settings: "Paramètres",
  settingsMenu: {
    settingsMenu: "Menu 'Paramètres'",
    allowDnD: "Permetre le glisser-déposer",
  },
  navigation: "Navigation",
  filter: {
    hideFilter: "Cacher le filtre",
    showFilter: "Montrer le filtre",
  },
  searchFilter: {
    currency: "Devise",
    selectCurrency: "Sélectionner la devise",
    textFrom: "{{text}} (depuis)",
    textTo: "{{text}} (vers)",
    fromValue: "De {{value}}",
    toValue: "à {{value}}",
    searchText: "Chaîne de recherche",
    today: "Aujourd'hui",
  },
  ofTotal: "sur {{count}}",
  zoomIn: "Agrandir",
  zoomOut: "Réduire",
  approveOrders: "Approuver la(les) Commande(s)",
  adjustmentDate: "Date de l'ajustement",
  chargeType: "Type d'imputation",
  generalLedgerInfo: "Information du Grand Livre",
  taxBundleInfo: "Information du régime de taxation",
  adjustmentDescription: "Description de l'ajustement",
  adjustmentDescriptionHelper:
    "Selectionner or saisissez une description qui apparaîtra sur la facture.",
  decision: "Décision",
  earliestInvoiceDate: "Facturation au plus tôt le",
  enableImmediateInvoicing: "Permettre la facturation immédiate",
  inputAmountInclVat: "Saisir le montant, TVA  {{vatRate}} inclue)",
  amountExclVat: "Montant (TVA {{vatRate}} exclue)",
  amountVat: "Taxe sur la valeur ajoutée (TVA)",
  amountInclVat: "Montant (TVA {{vatRate}} inclue)",
  amountsInclTax: "Afficher/entrer les montants taxes inclues",
  effectiveDate: "Date effective",
  overrideBinds:
    "Passer outre la durée minimum contractuelle/période de préavis",
  reason: "Motif",
  supressFees: "Suprimer les pénalités d'interruption de contrat",
  categoryWidget: {
    noCategoriesFound: "Aucun catégorie trouvée",
    noCategoriesFoundHelper: "Aucune catégorie n'a été trouvée pour ce client",
    missingCustomerScreenEntity:
      "Il manque une entité d'écran de type CUSTOMER",
    missingCustomerScreenEntityHelper:
      "Le composant n'a pas trouvé d'entité CUSTOMER parmi les entités d'écran",
  },
  historyWidget: {
    message: "Message",
    oldValue: "Valeur précédente",
    newValue: "Nouvelle valeur",
    orderId: "Identifiant de commande",
    dateLastPost: "Date de derniére modification {{date}}",
  },
  dayOfMonth: "jour du mois",
  days: "Jours",
  amount: "Montant",
  amountWithCurrency: "Montant ({{currencyCode}})",
  current: "Actuel",
  totalAmount: "Total {{amount}}",
  createdBy: "Créé par: {{createdBy}}",
  scoreWidget: {
    score: "Cote: {{score}}",
    noScore: "Aucune cote",
  },
  reminderXSent: "Rappel {{number}} envoyé",
  titleIsMissing: "Titre manquant",
  noChildWidget:
    "Pas de composant trouvé pour le type d'entité: {{EntityType}},  entityTypeID: {{number}}",
  descriptionIsMissing: "Description manquante",
  noResultsFound: "Aucun résultat n'a été trouvé",
  alerts_one: "{{count}} alerte",
  alerts_other: "{{count}} alertes",
  unknownEntity: "Entité inconnue",
  breadcrumb: "Fil d'Ariane",
  selectAll: "Tout sélectionner",
  items_one: "{{count}} élément",
  items_other: "{{count}} éléments",
  appNameLogo: "{{appName}} logo",
  footer:
    "Xplora est vendu et distribué par: Xplora Technologies SAS,  17 rue Galilée, 75116 Paris\nImmatriculation : 917 961 187 RCS Paris",
  yes: "Oui",
  no: "Non",
  choose: "Choisir",
  identifier: "Identifiant",
  score: "Cote",
  check: "Vérifier",
  creditScore: "Cote de crédit",
  creditStatus: "Statut",
  creditRatingStatus: {
    notChecked: "Non vérifiée",
    creditOk: "Bonne",
    creditWarning: "Limite",
    creditNotOk: "Mauvaise",
  },
  productOfferingOptions: {
    availableRenewalOptions:
      "Il y a {{optionsLength}} possibilités de renouvellement disponibles.",
    noRenewalOptions:
      "Il n'y a aucune possibilité de renouvellement. Le produit n'est pas actif, ou est contraint par des engagements contractuels.",
    payWithCurrent: "Payer avec la méthode courante: {{current}}",
    payWithMethod: "Payer avec {{method}}",
    readAndApprove: "J'ai lu et j'approuve les",
    termsAndConditions: "termes et conditions de vente.",
    payAndRenew: "Renouveller et payer {{amountToPay}}",
  },
  description: "Description",
  applicablePrices: "Prix en vigueur",
} as const;
