export default {
  urlNotFound: "URL finns inte",
  notFoundOrNotAvailable:
    "Källan du förfrågade hittas inte eller finns inte längre",
  yourApplications: "Dina Applikationer",
  homePage: "Appar",
  applications: "Dina Appar",
  customerCare: "Kundtjänst",
  printerQueue: "Printerköer",
  loading: "Laddar...",
  loadingApp: "Laddar in webbapplikationen...",
  logout: "Logga ut",
  rowsperpage: "Rader per sida",
  switchTo: "Byt till",
  yourChangesAreSaved: "Ändringarna har sparats.",
  filters: "Filter",
  configuration: "Konfiguration",
  customerGroup: "Operatör",
  help: "Hjälp",
  notifications: "Notifikationer",
  dashboard: "Översikt",
  documentation: "Dokumentation",
  downloadingDocument: "Hämtar dokument...",
  loadingDocument: "Laddar in dokument...",
  noDocumentAvailable:
    "Det önskade dokumentet har antingen begränsad åtkomst eller är otillgängligt just nu",
  nextPage: "Next page",
  previousPage: "Previous page",
  downloadingFile: "Hämtar {{fileName}}",
  downloadFile: "Ladda ner {{fileName}}",
  fileName: "Filnamn",
  about: "Om ...",
  version: "Version: {{version}}",
  errorNoIdentityProvider:
    "Det gick inte att hämta någon identitetsleverantör från databasen.",
  name: "Namn",
  customerName: "Kundnamn",
  status: "Status",
  address: "Adress",
  invoices: "Fakturor",
  inventories: "Inventarier",
  type: "Typ",
  products: "Produkter",
  showHideButton: {
    show: "Expandera komponenten",
    hide: "Minimera komponenten",
  },
  bookmarks: {
    noFoundBookmarks: "Det finns inga {{entityType}}-bokmärken",
    noFoundEntityBookmarks: "Det finns inga bokmärken",
    addToBookmarks: "Lägg till bokmärken",
    removeFromBookmarks: "Ta bort från bokmärken",
  },
  entityTypeName: {
    account_one: "Konto",
    account_other: "Konton",
    address_one: "Adress",
    address_other: "Adresser",
    adjustment_one: "Justering",
    adjustment_other: "Justeringar",
    webApplication_one: "Applikation",
    webApplication_other: "Applikationer",
    customer_one: "Kund",
    customer_other: "Kunder",
    document_one: "Dokument",
    document_other: "Dokument",
    invoice_one: "Faktura",
    invoice_other: "Fakturor",
    inventory_one: "Inventarie",
    inventory_other: "Inventarier",
    message_one: "Meddelande",
    message_other: "Meddelanden",
    note_one: "Anteckning",
    note_other: "Anteckningar",
    npcase_one: "Porterings-Case",
    npcase_other: "Porterings-Case",
    payment_one: "Betalning",
    payment_other: "Betalningar",
    product_one: "Produkt",
    product_other: "Produkter",
    productOffering_one: "Produkterbjudande",
    productOffering_other: "Produkterbjudanden",
    subscription_one: "Abonnemang",
    subscription_other: "Abonnemang",
    task_one: "Ärende",
    task_other: "Ärenden",
    tasklist_one: "Ärendelista",
    tasklist_other: "Ärendelistor",
    user_one: "Användare",
    user_other: "Användare",
    nrdbMessage_one: "Meddelande",
    nrdbMessage_other: "Meddelanden",
    nrdbNumber_one: "Telefonnummer",
    nrdbNumber_other: "Telefonnummer",
    nrdbProxy_one: "Proxy",
    nrdbProxy_other: "Proxys",
    order_one: "Order",
    order_other: "Ordrar",
  },
  dateGroupTitle: {
    today: "Idag",
    last_week: "Under den senaste veckan",
    last_month: "Under den senaste månaden",
    earlier: "Tidigare",
  },
  sideBar: {
    show: "Visa",
    hide: "Dölj",
    showTooltip: "Visa navigationsmenyn",
    hideTooltip: "Dölj navigationsmenyn",
  },
  loggedOut: {
    message: "Du har blivit utloggad",
    gotoLogin: "Till loginsidan",
  },
  properties: "Egenskaper",
  noAvailableData: "Ingen tillgänglig data",
  noValue: "<inget värde>",
  noValueSelected: "<Ingen vald>",
  deleteSelected: "Ta bort valda objekt?",
  copyFrom: "Kopiera från",
  buttons: {
    accept: "Acceptera",
    cancel: "Avbryt",
    continue: "Fortsätt",
    clear: "Rensa",
    close: "Stäng",
    copy: "Kopiera",
    copyFrom: "Kopiera från {{addressType}} adress",
    create: "Skapa",
    decline: "Avböj",
    delete: "Ta Bort",
    dismiss: "Ångra",
    download: "Ladda ner",
    ok: "Ok",
    print: "Skriv ut",
    refreshPageData: "Uppdatera siddata",
    reset: "Rensa",
    save: "Spara",
    update: "Uppdatera",
    search: "Sök",
    undo: "Ångra",
    moreDetails: "Mer information",
    showAll: "Visa alla",
    showAllHiddenAfter: "resultat for {{search}} i {{entityType}}",
    showCountMore: "Visa {{count}} fler",
    showPDF: "Visa PDF",
    preview: "Förhandsvisa",
    previewHiddenAfter: "nästa faktura",
    next: "Nästa",
    back: "Tillbaka",
    finish: "Slutför",
    generateGUID: "Generera GUID",
    clearGUID: "Ta bort GUID",
    generatePINCode: "Generera PIN-kod",
    clearPINCode: "Ta bort PIN-kod",
    toggleVisibility: "Växla lösenordssynlighet",
    currentUserAccount: "Konto för den nuvarande användaren",
    accountMenu: "Kontomeny",
    applicationSwitcher: "Applikationsbytare",
  },
  forms: {
    fieldIsMandatory: "Fältet är obligatoriskt",
    mandatory: "{{label}} (obligatoriskt)",
    minLength: "Fältet kräver minst {{count}} tecken",
    maxLength: "Fältet kräver {{count}} tecken eller mindre",
    lengthBetween:
      "Fältet kräver mellan {{minLength}} och {{maxLength}} tecken",
    lengthExactly: "Fältet kräver exakt {{count}} tecken",
    pattern: "Fältet kräver formatet {{pattern}}",
    formatShortSocialSecNo: "ÅÅMMDD-XXXX",
    formatLongSocialSecNo: "ÅÅÅÅMMDD-XXXX",
    date: {
      dateTimeError: "Valt datum eller tid är ogiltigt (ledtråd: {{hint}})",
      dateError: "Valt datum är ogiltigt (ledtråd: {{hint}})",
      timeError: "Vald tid är ogiltig (ledtråd: {{hint}})",
    },
    tableSelectionIsMandatory: "En rad måste väljas",
  },
  table: {
    rowActionMenu: {
      tooltip: "Action för aktuell rad",
      ariaLabel: "Actionmeny",
    },
    multiRowActionMenu: {
      tooltip: "Actions för valda rader",
      ariaLabel: "Actionmeny",
    },
    expandRow: "Expandera raden",
    defaultError: "Ett fel uppstod vid inläsning av tabellen",
  },
  cookieText:
    "Billiant Web requires strictly necessary cookies to allow core website functionality such as user login and account management. The website cannot be used properly without strictly necessary cookies.",
  snackbarMessageOccurredMultipleTimes: "(förekom {{times}} gånger)",
  fetchingData: "Hämtar data",
  keyboardShortcuts: "Tangentbordsgenvägar",
  settings: "Inställningar",
  settingsMenu: {
    settingsMenu: "Meny för Inställningar",
    allowDnD: "Tillåt dra-och-släpp",
  },
  navigation: "Navigation",
  filter: {
    hideFilter: "Dölj filter",
    showFilter: "Visa filter",
  },
  searchFilter: {
    currency: "Valuta",
    selectCurrency: "Välj valuta",
    textFrom: "{{text}} (från)",
    textTo: "{{text}} (till)",
    fromValue: "Från {{value}}",
    toValue: "Till {{value}}",
    searchText: "Söktext",
    today: "Idag",
  },
  ofTotal: "av {{count}}",
  zoomIn: "Zooma in",
  zoomOut: "Zooma ut",
  approveOrders: "Godkänn ordrar",
  adjustmentDate: "Datum för justering",
  chargeType: "Typ av justering",
  generalLedgerInfo: "Bokföringskonto",
  taxBundleInfo: "Skattesatser",
  adjustmentDescription: "Beskrivning",
  adjustmentDescriptionHelper:
    "Välj eller ange en beskrivning som kommer att visas på fakturan.",
  decision: "Beslut",
  earliestInvoiceDate: "Tidigaste fakturadatum",
  enableImmediateInvoicing: "Omedelbar fakturering",
  inputAmountInclVat: "Ange belopp inklusive {{vatRate}} MOMS",
  amountExclVat: "Belopp (exklusive {{vatRate}} MOMS)",
  amountVat: "Mervärdesskatt (MOMS)",
  amountInclVat: "Belopp (inklusive {{vatRate}} MOMS)",
  amountsInclTax: "Visa/inmatade belopp inklusive MOMS",
  effectiveDate: "Ändringsdatum",
  overrideBinds: "Bryt bindningsregler",
  reason: "Orsak",
  supressFees: "Ignorera bindningsavgifter",
  categoryWidget: {
    noCategoriesFound: "Inga kategorier hittades",
    noCategoriesFoundHelper: "Denna kund har inga kategorier",
    missingCustomerScreenEntity:
      "Widgeten hittade inget kundnummer i skärmkomponenten",
    missingCustomerScreenEntityHelper:
      "Widgeten hittade ingen kund i skärmkomponentens entitets lista",
  },
  historyWidget: {
    message: "Meddelande",
    oldValue: "Gammalt värde",
    newValue: "Nytt värde",
    orderId: "Order-ID",
    dateLastPost: "Datum för senaste ändringen {{date}}",
  },
  dayOfMonth: "dag i månad",
  days: "Dagar",
  amount: "Belopp",
  amountWithCurrency: "Belopp ({{currencyCode}})",
  current: "Aktuell",
  totalAmount: "Totalt {{amount}}",
  createdBy: "Skapad av: {{createdBy}}",
  scoreWidget: {
    score: "Poäng: {{score}}",
    noScore: "Inget poäng",
  },
  reminderXSent: "Påminnelse {{number}} skickad",
  titleIsMissing: "Titel saknas",
  descriptionIsMissing: "Beskrivning saknas",
  noChildWidget:
    "Ingen matchande widget hittades. Entity type: {{EntityType}},  entity type ID: {{number}}",
  noResultsFound: "Hittade inga poster",
  alerts_one: "{{count}} varning",
  alerts_other: "{{count}} varningar",
  unknownEntity: "Okänd entitet",
  breadcrumb: "Synlig sökväg",
  selectAll: "Välj alla",
  items_one: "{{count}} föremål",
  items_other: "{{count}} föremål",
  appNameLogo: "{{appName}} logotyp",
  footer:
    "Xplora is sold and distributed by: XPLORA Mobile AB, Sjöängsvängen 6, 192 72 Sollentuna\nOrg.nr 559033-4735",
  yes: "Ja",
  no: "Nej",
  choose: "Välj",
  identifier: "Identifierare",
  score: "Poäng",
  check: "Kontrollera",
  creditScore: "Kreditpoäng",
  creditStatus: "Kreditstatus",
  creditRatingStatus: {
    notChecked: "Ej kontrollerad",
    creditOk: "Kreditvärdig",
    creditWarning: "Kreditvärdig med varning",
    creditNotOk: "Icke kreditvärdig",
  },
  productOfferingOptions: {
    availableRenewalOptions:
      "Det finns {{optionsLength}} förnyelsealternativ tillgängliga.",
    noRenewalOptions:
      "Inga förnyelsealternativ finns tillgängliga. Produkten är inte aktiv eller har bindningsregler.",
    payWithCurrent: "Betala med nuvarande {{current}}",
    payWithMethod: "Betala med {{method}}",
    readAndApprove: "Jag har läst och godkänner",
    termsAndConditions: "villkoren.",
    payAndRenew: "Förnya och betala {{amountToPay}}",
  },
  description: "Beskrivning",
  applicablePrices: "Tillämpliga priser",
} as const;
